import React from "react";
import { Layout } from "../../../components/Layout";
import { Hero } from "../../../components/Hero";
import { Paragraph } from "../../../components/Paragraph";
import SEO from "../../../components/SEO";
import Img from "gatsby-image";

const ccplanner = ({ data }) => (
  <Layout>
    <SEO
      title="ccPlanner"
      description="iOS app that helps you determine how many customer service agents you will need based on the expected number of calls, average call duration, and expected service level."
    />
    <div className="post-container post-full">
      <Hero
        title="ccPlanner"
        backgroundColorName="#121212"
        logo={data.ccPlannerLogo.publicURL}
      />
      <main className="post-content-container">
        <Paragraph>
          <br />
          <h1>Helping you manage your Call Center needs</h1>
          <span>
            ccPlanner helps you properly staff your Call Center based on
            expected call volume using tried and tested algorithms. ccPlanner is
            based on the erlang-c formula and does not require connection to the
            internet. It is written in Swift and uses SwiftUI and Catalyst for
            the UI.
          </span>
        </Paragraph>
        <br />
        <br />
        <Paragraph>
          <span>
            ccPlanner is available for <b>iOS</b> and <b>macOS</b>:
          </span>
        </Paragraph>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gridGap: 30,
            width: "80%",
            margin: "auto",
            paddingTop: 5,
            paddingBottom: 20
          }}
        >
          <div />
          <Img fluid={data.iOS.childImageSharp.fluid} objectFit="contain"></Img>
          <Img
            fluid={data.macOS.childImageSharp.fluid}
            objectFit="contain"
          ></Img>
          <div />
        </div>
        <Paragraph>
          <h2>Privacy Policy</h2>
          <span>
            ccPlanner does not collect or store any user data. ccPlanner does
            not require an internet connection and no user data is transmitted
            or stored in our servers.
          </span>
        </Paragraph>
      </main>
    </div>
  </Layout>
);

export default ccplanner;

export const query = graphql`
  query getCCPlannerImagePath {
    ccPlannerLogo: file(
      name: { eq: "ccplanner-logo" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    iOS: file(name: { eq: "ios" }, sourceInstanceName: { eq: "images" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    macOS: file(name: { eq: "macos" }, sourceInstanceName: { eq: "images" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
